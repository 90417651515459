body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: gold;
  font-size: 32px;
}

h3 {
  color: gold;
  font-size: 12px;
  font-weight: 500;
  text-decoration: underline;
  padding-top: 7px;
}

h4, h5 {
  position: absolute;
  margin-left: -21px;
  margin-top: -23px;
  font-size: 11px;
  color: #fff;
  font-weight: 100;
}

h4 {
  color: gold;
}

input[type=file] {
  display: block !important;
  right: 1px;
  top: 1px;
  height: 34px;
  opacity: 0;
  width: 100%;
  background: none;
  position: absolute;
  overflow: hidden;
  z-index: 2;
}
.control-fileupload {
  display: block;
  border: 1px solid #d6d7d6;
  background: #FFF;
  border-radius: 4px;
  width: 350px;
  height: 36px;
  line-height: 36px;
  padding: 0px 10px 2px 10px;
  overflow: hidden;
  position: relative;
  /* File upload button */
}
.control-fileupload:before,
.control-fileupload input,
.control-fileupload label {
  cursor: pointer !important;
}
.control-fileupload:before {
  /* inherit from boostrap btn styles */
  padding: 4px 12px;
  margin-bottom: 0;
  font-size: 14px;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e6e6e6));
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
  /* add more custom styles*/
  content: 'Browse';
  display: block;
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 2px;
  line-height: 20px;
  text-align: center;
}
.control-fileupload:hover:before,
.control-fileupload:focus:before {
  background-color: #e6e6e6;
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.2s ease-out;
  transition: background-position 0.2s ease-out;
}
.control-fileupload label {
  float: left;
  line-height: 32px;
  color: #999999;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  z-index: 1;
  margin-right: 90px;
  margin-bottom: 0px;
  cursor: text;
}

.output {
  font-size: 12px;
  height: 60vh;
  width: 350px;
  overflow-y: auto;
}

.total {
  border-color: gold !important;
  font-size: 12px;
  width: 350px;
}

.fade-in-left {
	-webkit-animation: fade-in-left 0.49s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: fade-in-left 0.49s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-7-4 10:10:37
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}